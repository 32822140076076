<template>
  <div class="body_">
    <my-fram>
      <div slot="default" class="content">
       <div class="top">
         <div class="top_left">当前位置：房管局介绍</div>
         <div class="top_right">
           <div class="button" @click="goHome">返回</div>
         </div>
       </div>
       <div class="foot">
         <div class="zero">
           <div :class="isslcd == 1 ? tab : tab_ " @click="showTab(1)">机构职责</div>
           <div :class="isslcd == 2 ? tab : tab_ " @click="showTab(2)">内设机构</div>
           <div :class="isslcd == 5 ? tab : tab_ " @click="showTab(5)">办公时间</div>
           <div :class="isslcd == 6 ? tab : tab_ " @click="showTab(6)">联系方式</div>
         </div>
         <div class="one">
           <div class="title_">
             <div class="t1" v-html="title_">
             </div>
           </div>
           <div class="cd" v-html="content_">
           </div>
         </div>
       </div>
      </div>
    </my-fram>
  </div>
</template>

<script>
import MyFram from '@/components/myFram'
export default {
  name: 'Introduce',
  components: { MyFram },
  data () {
    return {
      tab: 'tab',
      tab_: 'tab2',
      isslcd: 1,
      title_: '',
      content_: '',
      news: [{
        title: '东乡住建局',
        content: '<p>（1）贯彻执行国家住房和城乡建设行政管理的法律法规及省、市有关政策规定，负责拟订全市工程建设、城市建设、村镇建设、保障性住房建设、建筑业、住宅与房地产业、勘察设计咨询业、市政公用事业的政策、规章及中长期规划、改革方案并指导实施，研究提出全市住房和城乡建设重大问题的建议；负责住房和城乡建设行业管理。</p>\n' +
          '             <p>（2）负责指导全市城镇低收入家庭住房保障工作，会同有关部门编制全市住房保障年度计划、廉租住房补助资金计划并监督实施。</p>\n' +
          '             <p>（3）承担建立全市工程建设标准体系的责任；监督管理工程建设实施阶段国家标准、行业标准及定额的执行；组织制订、申报和发布市级行业标准、定额、建设项目可行性研究、经济评价方法、经济参数、建设标准、投资估算指标、建设工期定额、绿色建筑指标并监督实施；负责拟订工程造价管理制度，发布工程造价信息；负责拟订全市勘察设计咨询业管理的政策并监督实施。</p>\n' +
          '             <p>（4）负责拟订全市城市建设的政策和制度并监督实施；指导全市城市市政公用设施建设、安全和应急管理；指导城市管理工作；负责限额以内房屋建筑和城市基础设施工程项目初步设计审查和施工图设计文件审查监督；组织市区市政工程项目竣工综合验收；负责风景名胜区的审查报批和监督管理；负责城市园林绿化、市政公用事业等企业及其资质的管理；会同文物等有关主管部门审核世界自然与文化双重遗产的申报。</p>\n' +
          '             <p>（5）负责拟订全市小城镇和村庄建设的政策、制度并监督实施；指导农村住房建设和安全及危旧房改造；指导小城镇和村庄人居生态环境的改善工作；指导村镇统一开发、综合建设、房地产管理和重点镇的建设等工作。</p>\n' +
          '             <p>（6）负责工程建设实施阶段的综合管理工作；负责拟订规范建筑市场各方主体行为的规章制度并监督实施；负责建筑业企业及其资质、注册人员执业资格管理；指导全市建筑活动，组织实施全市房屋建筑工程、市政建设工程及建筑材料和安装设备采购的招标投标监督；负责全市有形建筑市场的监督管理工作</p>'
      }, {
        title: '内设机构',
        content: '<p>（一）办公室\n' +
          '　　负责办理局机关的党务、政务、事务及所属单位的综合协调和督查督办工作；承担文秘、档案、会务、保密、提议案办理、信访、宣传、计划生育、安全保卫和信息化建设工作；负责局机关对外联络和接待；承担全市建设系统目标管理考核、工青妇等工作；承担局机关及所属单位的人事劳资、社保医保工作；承担全市建设行业专业技术人员职务评聘、继续教育、年度考核、执业资格考核认定、注册和管理；负责干部、职工人事档案管理；承担离退休人员管理服务工作；承办纪检监察和党风廉政建设相关工作。</p>\n' +
          '             <p>（二）计划财务科\n' +
          '　　贯彻执行有关的财经法律法规和政策制度；承办年度部门预算的编制、审查和报批；负责衔接、下达城市建设、村镇建设、抗震加固等建设项目投资计划；承担建设行业行政事业性费用、各项基金的收缴、使用与监督、管理；负责对所属单位预决算、财务收支的审计检查；负责财务收支预、决算的执行和固定资产管理；承担机关事务管理，机关维稳、安全管理等工作；负责筹措人民防空建设资金、组织人民防空工程的经费预决算等工作职责</p>\n' +
          '             <p>（3）承担建立全市工程建设标准体系的责任；监督管理工程建设实施阶段国家标准、行业标准及定额的执行；组织制订、申报和发布市级行业标准、定额、建设项目可行性研究、经济评价方法、经济参数、建设标准、投资估算指标、建设工期定额、绿色建筑指标并监督实施；负责拟订工程造价管理制度，发布工程造价信息；负责拟订全市勘察设计咨询业管理的政策并监督实施。</p>\n' +
          '             <p>（4）负责拟订全市城市建设的政策和制度并监督实施；指导全市城市市政公用设施建设、安全和应急管理；指导城市管理工作；负责限额以内房屋建筑和城市基础设施工程项目初步设计审查和施工图设计文件审查监督；组织市区市政工程项目竣工综合验收；负责风景名胜区的审查报批和监督管理；负责城市园林绿化、市政公用事业等企业及其资质的管理；会同文物等有关主管部门审核世界自然与文化双重遗产的申报。</p>\n' +
          '             <p>（5）负责拟订全市小城镇和村庄建设的政策、制度并监督实施；指导农村住房建设和安全及危旧房改造；指导小城镇和村庄人居生态环境的改善工作；指导村镇统一开发、综合建设、房地产管理和重点镇的建设等工作。</p>\n' +
          '             <p>（6）负责工程建设实施阶段的综合管理工作；负责拟订规范建筑市场各方主体行为的规章制度并监督实施；负责建筑业企业及其资质、注册人员执业资格管理；指导全市建筑活动，组织实施全市房屋建筑工程、市政建设工程及建筑材料和安装设备采购的招标投标监督；负责全市有形建筑市场的监督管理工作</p>'
      }, {
        title: '机构设置',
        content: '<p>（1）贯彻执行国家住房和城乡建设行政管理的法律法规及省、市有关政策规定，负责拟订全市工程建设、城市建设、村镇建设、保障性住房建设、建筑业、住宅与房地产业、勘察设计咨询业、市政公用事业的政策、规章及中长期规划、改革方案并指导实施，研究提出全市住房和城乡建设重大问题的建议；负责住房和城乡建设行业管理。</p>\n' +
          '             <p>（2）负责指导全市城镇低收入家庭住房保障工作，会同有关部门编制全市住房保障年度计划、廉租住房补助资金计划并监督实施。</p>\n' +
          '             <p>（3）承担建立全市工程建设标准体系的责任；监督管理工程建设实施阶段国家标准、行业标准及定额的执行；组织制订、申报和发布市级行业标准、定额、建设项目可行性研究、经济评价方法、经济参数、建设标准、投资估算指标、建设工期定额、绿色建筑指标并监督实施；负责拟订工程造价管理制度，发布工程造价信息；负责拟订全市勘察设计咨询业管理的政策并监督实施。</p>\n' +
          '             <p>（4）负责拟订全市城市建设的政策和制度并监督实施；指导全市城市市政公用设施建设、安全和应急管理；指导城市管理工作；负责限额以内房屋建筑和城市基础设施工程项目初步设计审查和施工图设计文件审查监督；组织市区市政工程项目竣工综合验收；负责风景名胜区的审查报批和监督管理；负责城市园林绿化、市政公用事业等企业及其资质的管理；会同文物等有关主管部门审核世界自然与文化双重遗产的申报。</p>\n' +
          '             <p>（5）负责拟订全市小城镇和村庄建设的政策、制度并监督实施；指导农村住房建设和安全及危旧房改造；指导小城镇和村庄人居生态环境的改善工作；指导村镇统一开发、综合建设、房地产管理和重点镇的建设等工作。</p>\n' +
          '             <p>（6）负责工程建设实施阶段的综合管理工作；负责拟订规范建筑市场各方主体行为的规章制度并监督实施；负责建筑业企业及其资质、注册人员执业资格管理；指导全市建筑活动，组织实施全市房屋建筑工程、市政建设工程及建筑材料和安装设备采购的招标投标监督；负责全市有形建筑市场的监督管理工作</p>'
      }, {
        title: '下数机构',
        content: '<p style="text-align: center">庆阳市建筑业管理局</p>       <p style="text-align: center">   庆阳市房产服务中心</p>'
      }, {
        title: '<span style="font-size: 30px">办公时间</span>',
        content: '<p style="text-align: center;width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;margin-top: -5%;font-size: 4.5vw">' +
          '工作日：08:30—12:00   14:30—18:00</p>'
      }, {
        title: '<span style="font-size: 30px">联系方式</span>',
        content: '<p style="text-align: center;width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;margin-top: -5%;font-size: 4.5vw">办公室：0930-7122719</p>'
      }]
    }
  },
  mounted() {
   this.showTab(1);
  },
  methods: {
    goHome () {
      this.$router.push({ path: '/' })
    },
    showTab (index) {
      this.isslcd = index
      this.title_ = this.news[index - 1].title
      this.content_ = this.news[index - 1].content
    }
  }
}
</script>

<style scoped  lang="stylus">
.body_{
  height: 100%;
  width: 100%;
}
.content{
  height 100%
  width 100%
  display: flex;
  flex-direction: column;
  .top{
    width 100%
    height 15%
    display flex
    flex-direction row
    .top_left{
      width 65%
      line-height 100%
      font-size 1.725rem
      display flex
      flex-direction column
      justify-content center
      justify-items center
      color #FFFFFF
    }
    .top_right{
      width 35%
      line-height 100%
      font-size 1.725rem
      display flex
      flex-direction row
      justify-content right
      align-items center
      .button{
        width 5rem
        height 2.5rem
        line-height 2.4rem
        text-align center
        background-image linear-gradient(to bottom, #BDE4DD,#06B198)
        border-radius: .3rem;
        font-size 1.2rem
        color #fff
        margin-right 1rem
        cursor pointer
      }
    }
  }
  .foot{
    width 100%
    height 85%
    display: flex;
    flex-direction row
    border-radius: 1rem;
    border: 1px solid #82D5FF80 ;
    /*background-image: linear-gradient(180deg, rgba(28,44,119,0.67) 0%, rgba(33,51,135,0.67) 100%);*/
    background #DBEEEC
    overflow hidden
    .zero{
      width 10%
      border-right: 1px solid #82D5FF80 ;
      display flex
      flex-direction column
      justify-content center
      background #BCE1DF
      .tab{
        height calc(60% / 6)
        display flex
        flex-direction column
        justify-content center
        text-align center
        margin-bottom 0.1rem
        background #FFA800
        font-weight: 400;
        font-size: 22px;
        color: #fff;
        cursor pointer
      }
      .tab2{
        height calc(60% / 6)
        display flex
        flex-direction column
        justify-content center
        text-align center
        margin-bottom 0.1rem
        background: #00B097
        font-weight: 400;
        font-size: 22px;
        color: #FFFFFF;
        cursor pointer
      }
    }
    .one{
      width: 90%;
      height: 100%;
      display flex
      flex-direction column
      padding 1rem 1rem
      .title_{
        height 10%
        .t1{
          height 100%
          text-align center
          display flex
          flex-direction row
          justify-content center
          align-items center
          font-weight: 500;
          font-size: 1.5rem;
          color: #333333;
        }
      }
      .cd{
        height 90%
        font-size: 1.5rem;
        color: #333333;
        letter-spacing: 0;
        text-align: justify;
        line-height: 2.8rem;
        padding-top 1.6rem
        text-indent 3rem
        text-overflow: ellipsis;
        overflow-y auto
      }
    }
  }
}
</style>
